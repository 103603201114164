
/* eslint-disable @typescript-eslint/camelcase */
import {
  defineComponent,
  ref,
  onMounted
} from 'vue';
import { useRoute } from 'vue-router';

import BlockAdvertisement from './components/BlockAdvertisement.vue';
import BlockPhotos from './components/BlockPhotos.vue';
import BlockVideos from './components/BlockVideos.vue';
import BlockModels from './components/BlockModels.vue';
import {
  getBlock
} from '@/services/api';
import {
  PartialBlock,
  BlockType
} from '@/interfaces/Block';

export default defineComponent({
  components: {
    BlockAdvertisement,
    BlockPhotos,
    BlockVideos,
    BlockModels
  },
  setup() {
    const blockId = useRoute().params.id as string;
    const block = ref<PartialBlock>({});

    onMounted(async() => {
      const { data } = await getBlock({ blockId });
      block.value = data;
    });

    return {
      block,
      blockId,
      BlockType
    };
  }
});
